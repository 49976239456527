import { For } from 'solid-js';

import type { ContentDto, Genre } from '~/modules/api';

import { t } from '~/libs/i18n/utils';
import { isEmptyArray } from '~/utils';
import { CarouselGallery, Tile } from '../shared';
import { Slide } from '../ui';

interface TrendingGalleryProps {
  genre: Genre;
  title: string;
  items?: ContentDto[];
}

export function GenreGallery(props: TrendingGalleryProps) {
  if (isEmptyArray(props.items)) {
    return null;
  }

  return (
    <CarouselGallery
      id={'genre_' + props.genre}
      heading={{
        title: props.title,
        action: {
          url: '/genres/' + props.genre,
          label: t('common:buttons.viewAll'),
        },
      }}
    >
      <For each={props.items}>
        {item => (
          <Slide class="df-scrollable-list-item-4-col">
            <Tile
              preset="VerticalTileCondensedNoGenre"
              content={item}
              trackingProperties={{ section: 'genre-carousel:' + props.genre }}
            />
          </Slide>
        )}
      </For>
    </CarouselGallery>
  );
}
